<template>
  <AprilBody :hide-stepper="!canDisplayProgressBar">
    <PopinMaintenance v-if="stepsData.family.maintenance_mode" />
    <foundations-ui-wrapper>
      <div>
        <NuxtPage />
      </div>
    </foundations-ui-wrapper>
    <StickyBottom v-if="withStickyBottom" :disabled="false" />
  </AprilBody>
</template>

<script lang="ts">
import {
  flattenDeep /* eslint-disable-line import/named */,
  isEmpty /* eslint-disable-line import/named */,
  isNil /* eslint-disable-line import/named */,
  omitBy /* eslint-disable-line import/named */,
  some /* eslint-disable-line import/named */,
  startsWith /* eslint-disable-line import/named */,
} from 'lodash'
import { mapGetters } from 'vuex'
import { type CaptureContext } from '@sentry/types'

import { AprilCookie } from '~/plugins/cookies'
import { pushPageView } from '~/plugins/gtm.client'
import { type PrismicState } from '~/store/prismic/state'
import { RootState } from '~/store/state'
import { formatDateFromStateFormat, setValueInCookie } from '~/utils/cookies'
import { dateIsBefore, isValidHour } from '~/utils/date'
import { booleanToString } from '~~/utils/form'
import { TgpState } from '~~/store/tgp/state'
import { comparatorMissingDataPages } from '~~/utils/navigation'
import { sentryContext } from '~/utils/sentry'

const MAX_CREATE_PROJECT_RETRIES = 5

export default defineNuxtComponent({
  name: 'LayoutDefault',
  setup: () => {
    const i18n = useI18n()
    const router = useRouter()
    const route = useRoute()
    useHead({
      htmlAttrs: {
        lang: i18n.locale,
      },
    })

    watch(
      () => route.fullPath,
      () => {
        pushPageView()
      }
    )

    return {
      router,
      route,
      i18n,
    }
  },
  computed: {
    ...(mapGetters({
      stepsData: 'prismic/stepsData',
      comparatorOffer: 'tgp/comparatorOffer',
    }) as {
      stepsData: () => PrismicState['stepsData']
      comparatorOffer: () => TgpState['comparatorOffer']
    }),
    withStickyBottom() {
      if (this.isErrorPage) {
        return false
      }

      return !startsWith(this.route.name as string, 'emailconfirmation')
    },
    isComparatorPage() {
      return some(comparatorMissingDataPages, (item) =>
        startsWith(this.route.name as string, item)
      )
    },
    isErrorPage() {
      // check if the page is an error page
      // (I did not find a better way to do this)
      return (
        Object.prototype.hasOwnProperty.call(this.$nuxt, 'nuxt') &&
        Object.prototype.hasOwnProperty.call((this.$nuxt as any).nuxt, 'err') &&
        (this.$nuxt as any).nuxt.err
      )
    },
    canDisplayProgressBar() {
      if ((this.comparatorOffer && this.isComparatorPage) || this.isErrorPage) {
        return false
      }

      return true
    },
  },
  created() {
    // since component Nuxt does not trigger events emitted by sub-components, bind on Nuxt event
    // @see https://stackoverflow.com/a/67817642
    this.$bus.$on(
      'commit-project-situation',
      ($event: ProjectSituationPayload) => {
        this.onCommit('__april_project_situation', $event)
      }
    )

    this.$bus.$on('commit-project', ($event: ProjectPayload) => {
      this.onCommit('__april_project', $event)
    })

    this.$bus.$on('post-characteristics', () => {
      this.onPostCharacteristics()
    })
  },
  async mounted() {
    const state = this.$store.state as RootState
    const paramProjectId = this.route.params.projectId

    if (
      this.route.path.includes('souscription') &&
      this.route.params.projectId &&
      this.checkUtmUrl()
    ) {
      await this.fetchProject(this.route.params.projectId)
    }

    const { commercialOpportunityId, dateEffet, projectId } = state.tgp

    if (
      (isNil(projectId) || isNil(commercialOpportunityId)) &&
      isNil(paramProjectId)
    ) {
      this.createProject()
    }

    if (!isNil(dateEffet) && dateIsBefore(dateEffet, 1)) {
      const startDate = new Date()
      const validHour = isValidHour(this.stepsData.dateEffetDays)
      startDate.setDate(startDate.getDate() + (validHour ? 1 : 2))
      const newDateEffet = startDate.toLocaleDateString('fr')

      if (dateEffet !== newDateEffet) {
        this.$store.commit('tgp/setDateEffet', newDateEffet)
        this.onPostCharacteristics()
      }
    }
  },
  beforeDestroy() {
    // removing eventBus listener
    this.$bus.$off('commit-project-situation')
    this.$bus.$off('commit-project')
    this.$bus.$off('post-characteristics')
  },
  methods: {
    async fetchProject(projectId: string) {
      // set projectId according url (Resumption of subscription via e-mail quotation)
      this.$store.dispatch('tgp/setProjectId', projectId)

      const baseURL = import.meta.server
        ? (this.$root as any).context.$config.public.baseURL
        : window.location.origin

      await this.fetchCharacteristics(projectId, baseURL) // call /characteristics for recover projectMarketingId

      // call /by-marketing-id for recover id (= commercialOpportunityId)
      const projectMarketingId = this.$store.state.tgp.projectMarketingId

      await this.fetchByMarketingId(projectMarketingId, baseURL)
    },
    async fetchCharacteristics(projectId: string, baseURL: string) {
      const characteristicsPayload =
        (
          await fetch(
            `${baseURL}/api/characteristics?projectId=${projectId}`
          ).then((res) => res.json())
        ).data || []

      this.$store.dispatch(
        'tgp/setProjectMarketingId',
        characteristicsPayload.characteristics.projectMarketingId
      )
      this.$store.dispatch(
        'tgp/setDeductibilityMadelinLaw',
        booleanToString(
          characteristicsPayload.characteristics.deductibilityMadelinLaw
        )
      )
      this.$store.dispatch('tgp/setProjectSituation', {
        hasIndividualPolicyInsurance: booleanToString(
          characteristicsPayload.characteristics.hasIndividualPolicyInsurance
        ),
        isCancelableInsurance: booleanToString(
          characteristicsPayload.characteristics.isCancelableInsurance
        ),
      })
    },
    async fetchByMarketingId(marketingId: string, baseURL: string) {
      const byMarketingIdPayload =
        (
          await fetch(
            `${baseURL}/api/by-marketing-id?marketingId=${marketingId}`
          ).then((res) => res.json())
        ).data || []

      this.$store.dispatch(
        'tgp/setCommercialOpportunityId',
        byMarketingIdPayload.id
      )
    },
    checkUtmUrl() {
      const utmSource = this.route.query.utm_source
      const utmMedium = this.route.query.utm_medium
      const utmCampaign = this.route.query.utm_campaign

      return (
        utmSource === 'TGP_sante' &&
        utmMedium === 'Email' &&
        utmCampaign === 'demande-devis'
      )
    },
    async createProject() {
      const baseURL = this.$config.public.baseURL
      const campaignId = this.getCampaignIdQueryParam()
      const referral = this.getReferral()
      const gclId = this.getGclIdQueryParam()

      const payload = {
        campaignId,
        referral,
        gclId,
        landingPage: '',
      }

      const retryCreateProject = (retries: number, err: any): Promise<any> => {
        if (!retries) {
          return Promise.reject(err)
        }

        return fetch(`${baseURL}/api/project`, {
          method: 'POST',
          body: JSON.stringify(payload),
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json()
            }

            const errorPayload = {
              status: response.status,
              statusText: response.statusText,
              body: await response.text(),
              try: MAX_CREATE_PROJECT_RETRIES - retries,
            }

            const ctx: CaptureContext = sentryContext(
              this.$store.state.tgp,
              'create-project',
              'error',
              errorPayload
            )

            const { $sentryCaptureMessage } = useNuxtApp()

            $sentryCaptureMessage(
              `Create project API call failed, response status: ${response.status}.`,
              ctx
            )

            throw new Error(`Project not created`, { cause: errorPayload })
          })
          .catch((err) => {
            return retryCreateProject(retries - 1, err)
          })
      }

      try {
        const projectPayload = (
          await retryCreateProject(MAX_CREATE_PROJECT_RETRIES, null)
        ).data as {
          projectId: string
          commercialOpportunityId: string
        }

        this.$store.dispatch('tgp/setProjectId', projectPayload.projectId)
        this.$store.dispatch(
          'tgp/setCommercialOpportunityId',
          projectPayload.commercialOpportunityId
        )
      } catch (error: any) {
        const ctx: CaptureContext = sentryContext(
          this.$store.state.tgp,
          'create-project',
          'fatal',
          error.cause
        )

        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage(error.message, ctx)
      }
    },
    postProjectCharacteristics({
      projectId,
      commissionWishes,
    }: {
      projectId: string
      commissionWishes: string
    }) {
      const payload = omitBy(
        {
          projectId,
          commissionWishes,
          effectiveDate: formatDateFromStateFormat(
            this.$store.state.tgp.dateEffet
          ),
          hasIndividualPolicyInsurance:
            this.$store.state.tgp.projectSituation
              ?.hasIndividualPolicyInsurance,
          isCancelableInsurance:
            this.$store.state.tgp.projectSituation?.isCancelableInsurance,
          deductibilityMadelinLaw: false,
        },
        isNil
      )

      fetch(`${this.$config.public.baseURL}/api/characteristics`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
    },
    getCampaignIdQueryParam() {
      // override default TTL to make it session only
      const cookie = this.$cookies('cmpid', { maxAge: undefined })

      // get query param cmpid
      const cmpid = flattenDeep([this.route.query.cmpid])[0]

      if (!isNil(cmpid)) {
        cookie.value = cmpid.substring(0, 198) // truncate to 199 chars
      }

      return cookie.value
    },
    getGclIdQueryParam() {
      // get query param gclId
      return flattenDeep([this.route.query.gclid])[0]
    },
    getReferral() {
      // override default TTL to make it session only
      const cookie = this.$cookies('__april_ref', { maxAge: undefined })

      // get referrer from document
      const referrer = document.referrer

      if (
        !isEmpty(referrer) &&
        !startsWith(referrer, 'https://www.april.fr/')
      ) {
        cookie.value = referrer.substring(0, 198) // truncate to 199 chars
      }

      return cookie.value
    },
    // append payload values to the cookie on commit
    onCommit(
      cookieName: AprilCookie,
      payload: ProjectSituationPayload | ProjectPayload
    ) {
      const cookie = this.$cookies(cookieName)
      let { value: cookieValue } = cookie

      Object.entries(payload).forEach(([key, value]) => {
        cookieValue = setValueInCookie(
          key as ProjectSituationKey | ProjectKey,
          value,
          cookieValue || ''
        )
      })

      cookie.value = cookieValue
    },
    // push project characteristics on the API
    onPostCharacteristics() {
      // BUG: update the project characteristics will clear the needs
      const projectIds: { projectId: string; commissionWishes: string }[] = [
        {
          projectId: this.$store.state.tgp.projectId,
          commissionWishes: '0010',
        },
        {
          projectId: this.$store.state.tgp.projectIdPex,
          commissionWishes: '1515',
        },
      ].filter((item) => !isNil(item.projectId))

      // post characteristics for each project ID
      projectIds.forEach(this.postProjectCharacteristics)
    },
  },
})
</script>
