<template>
  <div id="sticky-bottom" class="sticky-container">
    <atoms-cta-link v-if="previousStep" @click="prevClick">
      Précédent
    </atoms-cta-link>

    <atoms-cta-label
      variant="attention"
      :class="{ '-disabled': disabled }"
      @click="nextClick"
    >
      {{ ctaNext }}
    </atoms-cta-label>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { StepState } from '~~/store/steps/state'

export default defineNuxtComponent({
  name: 'StickyBottom',
  data() {
    return {
      disabled: false,
      ctaNext: 'Suivant',
    }
  },
  computed: {
    ...(mapGetters({
      previousStep: 'steps/previousStep',
      currentStep: 'steps/currentStep',
    }) as {
      previousStep: () => string
      currentStep: () => StepState['currentStep']
    }),
  },
  watch: {
    $route(to) {
      if (to.path === '/signature') {
        this.ctaNext = 'Signer mon contrat'
      } else {
        this.ctaNext = 'Suivant'
      }
    },
  },
  mounted() {
    // receive disabled value from Step component
    this.$bus.$on('form-disabled', (disabled: boolean) => {
      this.disabled = disabled
    })

    if ((this.currentStep as string) === 'signature') {
      this.ctaNext = 'Signer mon contrat'
    }
  },
  beforeDestroy() {
    // removing eventBus listener
    this.$bus.$off('form-disabled')
  },
  methods: {
    nextClick() {
      // send click on next to Step component
      this.$bus.$emit('form-next')
    },
    prevClick() {
      // send click on prev to Step component
      this.$bus.$emit('form-prev')
    },
  },
})
</script>

<style scoped lang="scss">
.sticky-container {
  .cta {
    flex: 1;

    & + .cta {
      flex: 0 0 185px;
      width: 185px;
    }
  }
}
</style>
